import React, { useRef } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';
import { Translate, TFVButton } from '../../../Share/components';
import { getDateFormated } from '../../../Share/utils';
// eslint-disable-next-line react/prop-types

function PreviewEmgBooking({
  dateRange: { TimeFrom, TimeTo },
  language: { name },
  customerName,
  onClose,
  onSubmit,
  bookedInterpreterName,
  bookedInterpreterNumber,
  genderValue,
  genderRequirement,
}) {
  const componentRef = useRef(null);
  const dateTimeFrom = moment(TimeFrom);
  const dateTimeTo = moment(TimeTo);

  const endTime = getDateFormated(dateTimeTo, 'HH:mm, DD MMMM');

  let genderString = Translate({ content: 'general.none' });
  if (genderValue?.value === '1') {
    genderString = Translate({ content: 'general.male' });
  } else if (genderValue?.value === '2') {
    genderString = Translate({ content: 'general.female' });
  }

  return (
    <>
      <div className="d-flex flex-column pr-3">
        <div className="tv-single-assign-form__preview-sub-container">
          <div className="tv-single-assign-form__preview-headings">
            <Translate content="singleAssignment.bookingInformation" />
          </div>
          <div className="w-100 mt-2">
            <div ref={componentRef}>
              <InformationItem label="Bokning" content="Telefon" />
              {
                <InformationItem
                  label="Datum"
                  content={`${dateTimeFrom.format('HH:mm')} - ${endTime}`}
                />
              }
              <InformationItem
                label={Translate({
                  content: 'report.skill',
                })}
                content={name}
              />

              <InformationItem
                label={Translate({
                  content: 'singleAssignment.contactPerson',
                })}
                content={bookedInterpreterName}
              />
              <InformationItem
                label={Translate({
                  content: 'singleAssignment.contactOrderPerson',
                })}
                content={customerName}
              />
            </div>
            <>
              <hr className="my-0" />
              <div className="tv-single-assign-form__preview-sub-container">
                <div className="tv-single-assign-form__preview-headings">
                  <Translate content="singleAssignment.additionBookingInfo" />
                </div>
                <div className="w-100 mt-2">
                  <InformationItem
                    label={Translate({
                      content: 'singleAssignment.genderRequirement',
                    })}
                    content={genderString}
                    conditions={
                      genderString != '' &&
                      Translate({ content: 'general.none' }) !== genderString
                        ? genderRequirement
                          ? 'Krävs'
                          : 'Önskemål'
                        : ''
                    }
                  />
                </div>
              </div>
            </>
            <hr />
            <div className="tv-booknow-complete__data-item__sent-to">
              <ReactToPrint
                trigger={() => (
                  <div className="td-block ml-auto tv-workassignment__detail-links-item">
                    {Translate({
                      content: 'reportQuality.printButton',
                    })}
                  </div>
                )}
                content={() => componentRef.current}
              />
            </div>
            <div className="d-flex flex-column">
              <div className="tv-booknow__form__buttons tv-booknow__form__buttons--success tv-booknow-complete__buttons  justify-content-between tv-display-flex">
                <TFVButton
                  type="line-primary"
                  text={Translate({
                    content: 'singleAssignment.goBack',
                  })}
                  onClick={() => onClose()}
                />
                <TFVButton
                  type="secondary"
                  className="tv-single-assign-form__button-preview"
                  buttonType="button"
                  text={Translate({
                    content: 'previewAssignment.confirmNow',
                  })}
                  onClick={() => onSubmit()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

PreviewEmgBooking.propTypes = {
  orderNumber: PropTypes.string,
  bookedInterpreterName: PropTypes.string,
  bookedInterpreterNumber: PropTypes.string,
  dateRange: PropTypes.shape({
    TimeFrom: PropTypes.string,
    TimeTo: PropTypes.string,
  }),
  language: PropTypes.shape({
    name: PropTypes.string,
  }),
  customerName: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

PreviewEmgBooking.defaultProps = {
  orderNumber: '',
  bookedInterpreterName: '',
  bookedInterpreterNumber: '',
  language: {
    name: '',
  },
  dateRange: {
    TimeFrom: '00:00',
    TimeTo: '00:00',
  },
  customerName: '',
  onClose: () => {},
  onSubmit: () => {},
};

export default PreviewEmgBooking;

function InformationItem({ label, content, conditions }) {
  return (
    <div className="row">
      <div
        className={`tv-workassignment__modal-info-label col-4 col-lg-4 ${
          conditions ? 'mb-1' : ''
        }`}
      >
        {label}
      </div>
      <div
        className={`tv-workassignment__modal-info-content ${
          conditions ? 'col-5 mb-1 col-lg-4' : 'col-7 col-lg-7'
        }  `}
        title={content}
      >
        {content}
      </div>
      {conditions && (
        <div className="tv-workassignment__modal-info-condition  col-6  col-lg-3">
          {conditions}
        </div>
      )}
    </div>
  );
}
