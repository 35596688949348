import axios from 'axios';
import { errorInterceptor, responseInterceptor } from './multisoftInterceptor';

axios.defaults.baseURL = process.env.REACT_APP_MULTISOFT_API;
const apiKey = process.env.REACT_APP_MULTISOFT_API_KEY;

// multisoft interceptors
axios.interceptors.response.use(responseInterceptor, errorInterceptor);

// share API
export function getArticles() {
  return axios.post('/Billing/GetArticles', {
    ApiKey: apiKey,
  });
}

export function getNotifications(
  token,
  orderIdentifier,
  isUnreadOnly,
  datetimeSentFrom,
) {
  return axios.post('/Common/GetNotifications', {
    Token: token,
    OrderIdentifier: orderIdentifier,
    DoOnlyIncludeUnreadNotifications: isUnreadOnly,
    DatetimeSentFrom: datetimeSentFrom,
  });
}

export function setNotificationReadStatus(
  token,
  notificationIdentifier,
  isRead,
) {
  return axios.post('/Common/SetNotificationReadStatus', {
    Token: token,
    NotificationIdentifier: notificationIdentifier,
    IsRead: isRead,
  });
}

export function getCancellationReasons() {
  return axios.post('/CRMCustomer/GetOrderCancellationReasons', {
    ApiKey: apiKey,
  });
}

// API Client Portal
export function getCustomer(token) {
  return axios.post('/CRMCustomer/GetCustomer', {
    Token: token,
  });
}

export function validateCustomerPassword(
  customerNumber,
  passwordHash,
  customerEmail,
) {
  if (customerEmail) {
    return axios.post('/CRMCustomer/ValidateCustomerPassword', {
      ApiKey: apiKey,
      CustomerNumber: customerNumber,
      PasswordHash: passwordHash,
      EmailAddressContactPerson: customerEmail,
    });
  }
  return axios.post('/CRMCustomer/ValidateCustomerPassword', {
    ApiKey: apiKey,
    CustomerNumber: customerNumber,
    PasswordHash: passwordHash,
  });
}

export function setCustomerPassword(token, passwordHash) {
  return axios.post('/CRMCustomer/SetCustomerPassword', {
    Token: token,
    PasswordHash: passwordHash,
  });
}

export function authenticateCustomerUser(
  customerNumber,
  email,
  isContactPerson,
) {
  return axios.post('/CRMCustomer/AuthenticateCustomerUser', {
    ApiKey: apiKey,
    CustomerNumber: customerNumber,
    EmailAddress: email,
    IsContactPerson: isContactPerson,
  });
}

export function setCustomerRating(
  token,
  orderIdentifier,
  rate,
  comment,
  reason,
) {
  return axios.post('/CRMCustomer/RateOrder', {
    Token: token,
    OrderIdentifier: orderIdentifier,
    RatingCustomer: rate,
    Comment: comment,
    ComplaintReasonIdentifier: reason,
  });
}
export async function searchOrders(
  token,
  datetimeFrom,
  datetimeTo,
  orderNumber,
  queryValue,
  orderIdentifier,
) {
  const response = await axios.post('/CRMCustomer/SearchOrders', {
    Token: token,
    DatetimeFrom: datetimeFrom,
    DatetimeTo: datetimeTo,
    OrderNumber: orderNumber === '' ? null : orderNumber,
    Keywords: queryValue === '' ? null : queryValue,
    OrderIdentifier: orderIdentifier === '' ? null : orderIdentifier,
  });

  if (
    response &&
    response.data &&
    response.data.Orders &&
    response.data.Orders instanceof Array &&
    response.data.Orders.length > 0
  ) {
    const formattedData = response.data.Orders.map(item => {
      const formattedItem = {
        ...item,
      };
      formattedItem.customerOrderEmailAddress = item.CustomerOrderEmailAddress
        ? item.CustomerOrderEmailAddress.split(';')
        : [];
      formattedItem.lmaNumber = item.LMANumber;
      formattedItem.message = item.Message;
      formattedItem.messageReceiver = item.MessageReceiver;
      formattedItem.messageTelephone = item.MessageTelephone;
      formattedItem.assignmentDescription = item.NotesResource
        ? `${item.NotesResource}`
        : '';
      formattedItem.assignmentSubskill = item.AssignmentSubskill;
      formattedItem.requestedInterpreter = item.PreferredResourceCustomer
        ? item.PreferredResourceCustomer
        : null;

      if (item.AdditionalRequirements && item.AdditionalRequirements != '') {
        formattedItem.additionalRequirements = item.AdditionalRequirements;
        formattedItem.additionalRequirements =
          formattedItem.additionalRequirements.indexOf('||') === -1
            ? formattedItem.additionalRequirements
            : '';
      }

      return formattedItem;
    });
    response.data.Orders = formattedData;
  }

  return response;
}

export function getSkills(ForAutoAssignOrder) {
  return axios.post('/CRMCustomer/GetSkills', {
    ApiKey: apiKey,
    ForAutoAssignOrder,
  });
}

export function getEducationSkills(ForAutoAssignOrder) {
  return axios.post('/CRMCustomer/GetSkills/Education', {
    ApiKey: apiKey,
    ForAutoAssignOrder,
  });
}

export async function fetchContactPersons(
  token,
  ContactPersonIdentifier = null,
) {
  const response = await axios.post('/CRMCustomer/GetContactPersons', {
    Token: token,
    ContactPersonIdentifier,
  });
  if (
    response &&
    response.data &&
    response.data.CustomerContactPersons &&
    response.data.CustomerContactPersons instanceof Array
  ) {
    response.data.CustomerContactPersons.sort((a, b) =>
      `${a.FirstName} ${a.LastName}`.trim().toLowerCase() ===
      `${b.FirstName} ${b.LastName}`.trim().toLowerCase()
        ? 0
        : `${a.FirstName} ${a.LastName}`.trim().toLowerCase() >
          `${b.FirstName} ${b.LastName}`.trim().toLowerCase()
        ? 1
        : -1,
    );
  }
  return response;
}

/**
 * Returns a list of active services for the customer, together with contract, invoice receivers and available competences that are connected to each service.
 * @param {string} token login token
 * @param {string} skillExternalidentifier id of selected language in Create Order form
 * @param {Date} startTime start time value of the first session in Create Order form
 * @param {Date} endTime end time value of the first session in Create Order form
 */
export function fetchServices(
  token,
  skillExternalidentifier,
  startTime,
  endTime,
) {
  return axios.post('/CRMCustomer/GetServices', {
    Token: token,
    SkillExternalidentifier: skillExternalidentifier,
    DatetimeFrom: startTime,
    DatetimeTo: endTime,
  });
}

export function createNewOrder(token, assignment) {
  return axios.post('/CRMCustomer/CreateNewOrder', {
    Token: token,
    AttachmentData: assignment.AttactmentData,
    SkillEducationPreferredIdentifier:
      assignment.SkillEducationPreferredIdentifier,
    ContactPersonOrdererRequestData: assignment.ContactPersonOrdererRequestData,
    ContactPersonUponInterpretationRequestData:
      assignment.ContactPersonUponInterpretationRequestData,
    CustomerOrderNumber: assignment.yourOrderNumber,
    CustomerReference: assignment.yourReferenceNumber,
    DoRequireCompetenceLeverOrHigher: assignment.isAllowLowerLevel,
    DoRequireExactCompetenceLevel: assignment.isAllowOnlyLevel,
    DoRequireCorrectGender: assignment.DoRequireCorrectGender,
    GenderIdPreferred: assignment.GenderIdPreferred,
    InvoiceReceiverIdentifier: assignment.InvoiceReceiverIdentifier,
    NotesResource: assignment.NotesResource,
    AdditionalRequirements: assignment.AdditionalRequirements,
    PreferredResourceCustomer: assignment.PreferredResourceCustomer,
    PreferredResourceLevel: assignment.PreferredResourceLevel,
    OrderGroupRequestData: assignment.OrderGroupRequestData,
    OrderOccasions: assignment.orderOccasions,
    PostalAddressPerformingRequestData: assignment.deviatingAddress,
    SkillIdentifier: assignment.language.value,
    SkillSubstituteIdentifier: assignment.alternativeLanguage.value,
    DigitalMeetingPlatformIdentifier: assignment.meetingPlatform.value,
    DoRequireBankIdVerification: assignment.isBankIDRequired,
    MeetingPassCode: assignment.meetingPassCode,
    MeetingPhoneNumber: assignment.phoneNumber,
    MeetingUrl: assignment.meetingLink,
    LMANumber: assignment.lmaNumber,
    Message: assignment.message,
    MessageReceiver: assignment.messageReceiver,
    MessageTelephone: assignment.messageTelephone,
    CustomerOrderEmailAddress: assignment.CustomerOrderEmailAddress,
    DoRequireSkillEducation: assignment.DoRequireSkillEducation,
    DoForce: assignment.DoForce,
    IsPatientConsentGiven: assignment.IsPatientConsentGiven,
  });
}

export function updateOrder(token, assignment) {
  return axios.post('/CRMCustomer/UpdateOrder', {
    Token: token,
    AdditionalRequirements: assignment.AdditionalRequirements,
    CompetenceIdentifier: assignment.CompetenceIdentifier,
    CustomerOrderNumber: assignment.yourOrderNumber,
    CustomerReference: assignment.yourReferenceNumber,
    DatetimeFrom: assignment.orderOccasions[0].DatetimeFrom,
    DatetimeTo: assignment.orderOccasions[0].DatetimeTo,
    DigitalMeetingPlatformIdentifier: assignment.meetingPlatform.value,
    DoRequireBankIdVerification: assignment.isBankIDRequired,
    DoRequireCompetenceLeverOrHigher: assignment.isAllowLowerLevel,
    DoRequireExactCompetenceLevel: assignment.isAllowOnlyLevel,
    DoRequireCorrectGender: assignment.DoRequireCorrectGender,
    GenderIdPreferred: assignment.GenderIdPreferred,
    MeetingPassCode: assignment.meetingPassCode,
    MeetingPhoneNumber: assignment.phoneNumber,
    MeetingUrl: assignment.meetingLink,
    NotesResource: assignment.NotesResource,
    OrderIdentifier: assignment.OrderIdentifier,
    PreferredResourceCustomer: assignment.PreferredResourceCustomer,
    PreferredResourceLevel: assignment.PreferredResourceLevel,
    ServiceIdentifier: assignment.orderOccasions[0].ServiceIdentifier,
    SkillIdentifier: assignment.language.value,
    SkillSubstituteIdentifier: assignment.alternativeLanguage.value,
    UpdateAttachmentData: assignment.AttactmentData,
    UpdateContactPersonOrdererRequestData:
      assignment.ContactPersonOrdererRequestData,
    UpdateContactPersonUponInterpretationRequestData:
      assignment.ContactPersonUponInterpretationRequestData,
    UpdatePostalAddressPerformingRequestData: assignment.deviatingAddress,
    Message: assignment.message,
    MessageReceiver: assignment.messageReceiver,
    MessageTelephone: assignment.messageTelephone,
    LMANumber: assignment.lmaNumber,
    CustomerOrderEmailAddress: assignment.CustomerOrderEmailAddress,
    DoForce: assignment.DoForce,
  });
}

export function cancelOrder(
  token,
  cancellationReasonIdentifier,
  workAssignmentIdentifier,
  doCancelAdditionalServices,
) {
  return axios.post('/CRMCustomer/CancelOrder', {
    OrderCancellationReasonIdentifier: cancellationReasonIdentifier,
    Token: token,
    OrderIdentifier: workAssignmentIdentifier,
    DoCancelAdditionalServices: doCancelAdditionalServices,
  });
}

export function getAttachmentCustomer(token, attachmentIdentifier) {
  return axios.post('/CRMCustomer/GetAttachmentCustomer', {
    Token: token,
    AttachmentIdentifier: attachmentIdentifier,
  });
}

export function getComplaintReasonDeliveryRequest() {
  return axios.post('/CRMCustomer/GetComplaintReasonDelivery', {
    ApiKey: apiKey,
  });
}

export function getDeviations(data) {
  const {
    Token,
    DateFrom,
    DateTo,
    DeviationStatusId,
    OrderIdentifier,
    OrderNumber,
  } = data;

  return axios.post('/CRMCustomer/GetDeviations', {
    Token,
    DateFrom,
    DateTo,
    DeviationStatusId,
    OrderIdentifier,
    OrderNumber,
  });
}
export function createDeviation(data) {
  const {
    Token,
    OrderIdentifier,
    ContactPersonIdentifier,
    ComplaintReasonIdentifier,
    DeviationDescription,
    Documents,
  } = data;
  return axios.post('/CRMCustomer/CreateDeviation', {
    Token,
    OrderIdentifier,
    ContactPersonIdentifier,
    ComplaintReasonIdentifier,
    DeviationDescription,
    Documents,
  });
}

/**
 * Returns a time report signature
 * @param {string} token login token
 * @param {string} orderIdentifier id of assignment
 */
export async function getTimeReportSignature(token, orderIdentifier) {
  const response = await axios.post('/CRMCustomer/GetTimeReportSignature', {
    Token: token,
    OrderIdentifier: orderIdentifier,
  });
  return response;
}

/**
 * Returns a reasons for minimum rating
 * @param {string} token login token
 */
export async function fetchRatingReasons(token) {
  const response = await axios.post('/CRMCustomer/GetComplaintReason', {
    // Token: token,
    ApiKey: apiKey,
  });
  return response;
}

export function deletContactPerson(token, contactPersonIdentifier) {
  return axios.post('/CRMCustomer/DeleteContactPerson', {
    Token: token,
    ContactPersonIdentifier: contactPersonIdentifier,
  });
}

export function availableResourceAutoAssign(
  token,
  ServiceIdentifier,
  SkillIdentifier,
  DatetimeFrom,
  DatetimeTo,
  SessionIdentifier,
  DoRequireCorrectGender,
  GenderIdPreferred,
) {
  return axios.post('/CRMCustomer/GetAvailableResourcesForAutoAssignOrder', {
    Token: token,
    ServiceIdentifier,
    SkillIdentifier,
    DatetimeFrom,
    DatetimeTo,
    SessionIdentifier,
    DoRequireCorrectGender,
    GenderIdPreferred,
  });
}

export function autoAssignCreateOrder(
  token,
  ServiceIdentifier,
  contactPerson,
  contactPersonUponInterpretation,
  language,
  dateRange,
  customer,
  SessionIdentifier,
  DoRequireCorrectGender,
  GenderIdPreferred,
) {
  const query = {
    AutoAssignOrderContactPersonOrdererRequestData: {
      ContactPersonIdentifier: contactPerson.ContactPersonIdentifier,
      Email: contactPerson.Email,
      FirstName: contactPerson.FirstName,
      LastName: contactPerson.LastName,
      PhoneNumberDirect: contactPerson.PhoneNumberDirect,
      PhoneNumberMobile: contactPerson.PhoneNumberMobile,
      Title: contactPerson.Title,
    },
    AutoAssignOrderContactPersonUponInterpretationRequestData: {
      ContactPersonIdentifier:
        contactPersonUponInterpretation.ContactPersonIdentifier,
      Email: contactPersonUponInterpretation.Email,
      FirstName: contactPersonUponInterpretation.FirstName,
      LastName: contactPersonUponInterpretation.LastName,
      PhoneNumberDirect: contactPersonUponInterpretation.PhoneNumberDirect,
      PhoneNumberMobile: contactPersonUponInterpretation.PhoneNumberMobile,
      Title: contactPersonUponInterpretation.Title,
    },
    CustomerOrderNumber: customer.customerNumber,
    ServiceIdentifier,
    SkillIdentifier: language.value,
    DatetimeFrom: dateRange.TimeFrom,
    DatetimeTo: dateRange.TimeTo,
    Token: token,
    SessionIdentifier,
    DoRequireCorrectGender,
    GenderIdPreferred,
  };
  return axios.post('/CRMCustomer/CreateNewAutoAssignOrder', {
    ...query,
  });
}

export function createUpdateContactPerson({
  token,
  ContactPersonIdentifier,
  Title,
  Email,
  FirstName,
  LastName,
  PhoneNumberDirect,
  PhoneNumberMobile,
}) {
  return axios.post('/CRMCustomer/CreateUpdateContactPerson', {
    Token: token,
    ContactPersonIdentifier:
      ContactPersonIdentifier == '' ? null : ContactPersonIdentifier,
    EmailAddress: Email == '' ? null : Email,
    FirstName,
    LastName,
    PhoneNumberDirect: PhoneNumberDirect == '' ? null : PhoneNumberDirect,
    PhoneNumberMobile: PhoneNumberMobile == '' ? null : PhoneNumberMobile,
    Title,
  });
}

export function getAutoAssignOpeningHours({ token, DateFrom, DateTo }) {
  return axios.post('/CRMCustomer/GetAutoAssignOrderOpeningHours', {
    Token: token,
    DateFrom,
    DateTo,
  });
}

export function createNewOrderTranslation(token, orderTranslation) {
  return axios.post('/CRMCustomer/CreateNewTranslationOrder', {
    Token: token,
    ContactPersonIdentifierOrderer:
      orderTranslation.ContactPersonIdentifierOrderer,
    DateDelivery: orderTranslation.DateDelivery,
    DeliveryPlan: orderTranslation.DeliveryPlan,
    Description: orderTranslation.Description,
    DoesNeedAQuote: orderTranslation.DoesNeedAQuote,
    ModeOfDelivery: orderTranslation.ModeOfDelivery,
    SkillIdentifierSource: orderTranslation.SkillIdentifierSource,
    TargetSkills: orderTranslation.TargetSkills,
    TranslationAttachments: orderTranslation.TranslationAttachments,
  });
}

export function getTranslationSkills() {
  return axios.post('/CRMCustomer/GetSkills/Translation', {
    ApiKey: apiKey,
  });
}
